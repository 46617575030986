import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { useAppSelector } from "../../store/store";
import SideLogo from "../../assets/images/logo-white.png";

import { anonymousUser } from "../../helper/service";

export default function SidebarComponent(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const paymentLoading = useAppSelector(
    (state: any) => state.notificationReducer.paymentLoading
  );

  const [isIframe, setIsIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  let isAdmin =
    localStorage.getItem("userType")?.toLocaleLowerCase() === "admin"
      ? true
      : false;

  const [currentSection, setCurrentSection] = useState<any>();
  const [isUserDisable, setIsUserDisable] = useState(true);
  const [sidebarLogoURL, setSidebarLogoURL] = useState("");
  const [isInIframe, setIsInIframe] = useState(false);

  useEffect(() => {
    if (window.top !== window.self) {
      setIsInIframe(true);
    }
  }, [location]);

  const sectionId = useAppSelector((state: any) => state.formReducer.sectionid);
  const { sidebarLogo } = useAppSelector((state: any) => state.billingReducer);

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  const [openSections, setOpenSections] = useState<any[]>([]);

  useEffect(() => {
    if (sidebarLogo !== null) {
      setSidebarLogoURL(sidebarLogo);
    }
  }, [sidebarLogo]);

  const toggleSection = (selectedId: any) => {
    if (openSections.includes(selectedId)) {
      // If the clicked section is already open, close it
      setOpenSections(openSections.filter((id) => id !== selectedId));
    } else {
      // If the clicked section is not open, close all other sections and open the clicked one
      setOpenSections([selectedId]);
    }
  };

  useEffect(() => {
    setCurrentSection(sectionId?.id);
  }, [sectionId?.id]);

  useEffect(() => {
    if (currentSection && !openSections.includes(currentSection)) {
      toggleSection(currentSection);
    }
  }, [currentSection]);

  useEffect(() => {
    if (bootstrapData)
      if (
        bootstrapData &&
        !bootstrapData?.isDisabled &&
        !bootstrapData?.isDeleted
      ) {
        setIsUserDisable(false);
      }
  }, [bootstrapData]);

  useEffect(() => {
    const detectIframe = () => {
      try {
        if (window.self !== window.top) {
          setIsIframe(true);
        } else {
          setIsIframe(false);
        }
      } catch (e) {
        setIsIframe(true);
      }
      setLoading(false);
    };

    detectIframe();
  }, []);

  // const showSidebar = () => {
  //   if (!anonymousUser() && !isUserDisable) {
  //     return true;
  //   } else if (isIframe) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  return (
    <aside
      id="sidebar-multi-level-sidebar"
      className="fixed top-0 left-0 z-[1200] bg-[#111827] h-full transition-transform -translate-x-full sm:translate-x-0"
      aria-label="Sidebar"
    >
      {loading ? (
        <Box className="sidebar-logo">Loading...</Box>
      ) : (
        <Box className="sidebar-logo">
          {isInIframe ? (
            sidebarLogoURL ? (
              <img
                src={sidebarLogoURL}
                alt="Law Firm Logo"
                className="sideLogo"
              />
            ) : (
              <></>
            )
          ) : (
            <img src={SideLogo} alt="Logo" className="sideLogo" />
          )}
        </Box>
      )}
      {paymentLoading ? <Box className="paymentLoader" /> : ""}
      {/* All Menus */}

      <div
        className={`${
          isAdmin ? "h-[calc(100%_-_179px)]" : "h-[calc(100%_-_165px)]"
        } px-[8px] overflow-x-hidden overflow-y-auto custom-scroll`}
      >
        {isAdmin && (
          <ul className="space-y-2 font-medium p-0">
            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/admin"}
                key={"admin"}
                onClick={() => navigate("/admin")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">Admin Home</span>
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/users"}
                key={"users"}
                onClick={() => navigate("/users")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">User</span>
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/form"}
                key={"form"}
                onClick={() => navigate("/form")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">Form</span>
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/refresh"}
                key={"refresh"}
                onClick={() => navigate("/refresh")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">Refresh</span>
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/form_emulation"}
                key={"form_emulation"}
                onClick={() => navigate("/form_emulation")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">
                    User Form Emulation
                  </span>
                </span>
              </NavLink>
            </li>

            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/b2b-entities"}
                key={"b2b-entities"}
                onClick={() => navigate("/b2b-entities")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">
                    B2B Entities
                  </span>
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/csv-management"}
                key={"csv-management"}
                onClick={() => navigate("/csv-management")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">
                    CSV Management
                  </span>
                </span>
              </NavLink>
            </li>
            <li className="sidebar-list sideBarShow">
              <NavLink
                to={"/flow-mappings"}
                key={"flow-mappings"}
                onClick={() => navigate("/flow-mappings")}
                className={`flex items-center justify-between px-2 py-2.5 text-white rounded-lg hover:text-gray-100 group`}
              >
                <span className="flex items-center">
                  <span className="text-[#6B7280] text-[14px]">
                    Flow Mappings
                  </span>
                </span>
              </NavLink>
            </li>
          </ul>
        )}
      </div>
    </aside>
  );
}
