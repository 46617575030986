import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Grid,
  Autocomplete,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  getAllUIUsers,
  getAllUiUserDataShareScope,
  getUiUserDataShareScope,
  postUiUserDataShareScope,
} from "../../store/thunk/refreshThunk";
import DeleteUserEmulation from "../../components/UserFormEmulation/deleteComponent";

interface EmulateUserData {
  expirationDtUtc: Date;
  id: number;
  scope: string;
  sharedWithUserEmail: string;
  sharedWithUserId: number;
  userEmail: string;
  userId: number;
}

const FormEmulation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    allUiUser,
    uiUserDataShareScope,
    allUiUserDataShareScope,
    emulateUserList,
  } = useAppSelector((state: any) => state.refreshReducer);
  const [user, setUser] = useState<any>(null);
  const [scope, setScope] = useState<any>(null);
  const [error, setError] = useState<any>({});
  const [open, setOpen] = useState(false);
  const [userOptions, setUserOptions] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tabValue, setTabValue] = React.useState<any>("1");
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [deleteCurrUser, setDeleteCurrUser] = useState<any>("");
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const loading = open && userOptions.length === 0;
  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;

  useEffect(() => {
    dispatch(getUiUserDataShareScope());
  }, []);

  useEffect(() => {
    if (tabValue == "1") {
      dispatch(getUiUserDataShareScope());
    } else if (tabValue == "2") {
      dispatch(getAllUiUserDataShareScope());
    }
  }, [tabValue]);

  useEffect(() => {
    if (loading) {
      dispatch(getAllUIUsers());
    }
  }, [loading]);

  useEffect(() => {
    setUserOptions(allUiUser);
  }, [allUiUser]);

  useEffect(() => {
    if (!isDelete) {
      setDeleteCurrUser("");
    }
  }, [isDelete]);

  const handleUserChange = (e: any, newValue: any) => {
    setUser(newValue);
  };

  const handleSubmit = () => {
    if (user?.id && scope) {
      setError({});
      const _request = {
        userId: user?.id,
        scope: scope,
      };
      dispatch(postUiUserDataShareScope(_request)).then((res: any) => {
        if (tabValue == "1") {
          dispatch(getUiUserDataShareScope());
        } else if (tabValue == "2") {
          dispatch(getAllUiUserDataShareScope());
        }
      });
    } else {
      setError({
        user: "User field is required",
        scope: "Scope fields is required",
      });
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleDeleteCurrUser = (val: any) => {
    setIsDelete(true);
    let tempEmulateUserData;

    if (tabValue === "1") {
      tempEmulateUserData = uiUserDataShareScope?.filter(
        (userData: EmulateUserData) => userData?.id === val
      );
    } else {
      tempEmulateUserData = allUiUserDataShareScope?.filter(
        (userData: EmulateUserData) => userData?.id === val
      );
    }

    if (tempEmulateUserData && tempEmulateUserData?.length) {
      setDeleteCurrUser(tempEmulateUserData[0]?.id);
    }
  };

  const handleEmulateCurrUser = (val: any, emulatingUser: any) => (e: any) => {
    const tempEmulateUserData = uiUserDataShareScope?.filter(
      (userData: EmulateUserData) => userData?.id === val
    );
    const billingEntityId = localStorage.getItem("billingEntityId");

    let updatedUserDataForIframe = {
      id: tempEmulateUserData?.userId,
      name: "Nilay",
      email: tempEmulateUserData?.userEmail,
      phone: "9998702223",
      progressPercentage: 25,
      lastLoginDtUtc: "2024-10-03T11:20:53.1517062",
      unreadMessageCount: 0
    }

    navigate("/iframe", { state: { role: 'lawyer', token: accessToken, user: updatedUserDataForIframe, billingEntityId: billingEntityId } });
  };

  const currEmulationcolumns = [
    {
      name: "userEmail",
      label: "User Name",
      options: {
        sort: true,
      },
    },
    {
      name: "sharedWithUserEmail",
      label: "Shared With User Email",
      options: {
        sort: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div>
              <Button
                variant="outlined"
                sx={{ background: "#eff6ff" }}
                onClick={handleEmulateCurrUser(
                  value,
                  tableMeta?.rowData?.length > 0 ? tableMeta?.rowData[0] : ""
                )}
              >
                Emulate
              </Button>
              <Button
                variant="outlined"
                color="error"
                sx={{ ml: 1 }}
                onClick={() => handleDeleteCurrUser(value)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    },
    {
      name: "scope",
      label: "Scope",
      options: {
        sort: true,
      },
    },
  ];

  const currEmulationOptions = {
    sort: false,
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    page: currentPage > 1 ? currentPage - 1 : 0,
    count: uiUserDataShareScope?.length,
    rowsPerPage: 10,
    selectableRowsHideCheckboxes: true,
    pagination: true,
  };

  const allEmulationcolumns = [
    {
      name: "userEmail",
      label: "User Name",
      options: {
        sort: true,
      },
    },
    {
      name: "sharedWithUserEmail",
      label: "Shared With User Email",
      options: {
        sort: true,
      },
    },
    {
      name: "id",
      label: "Action",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return (
            <div>
              <Button
                variant="outlined"
                color="error"
                sx={{ ml: 1 }}
                onClick={() => handleDeleteCurrUser(value)}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    },
  ];
  const allEmulationOptions = {
    sort: false,
    filter: false,
    print: false,
    download: false,
    search: false,
    viewColumns: false,
    rowsPerPageOptions: [10, 15, 20],
    page: currentPage > 1 ? currentPage - 1 : 0,
    count: allUiUserDataShareScope?.length,
    rowsPerPage: 10,
    selectableRowsHideCheckboxes: true,
    pagination: true,
  };

  const headerElement = document.getElementsByTagName("header");
  const bannerElement = document.getElementsByClassName("banner-height");

  const headerHeight = bannerElement
    ? bannerElement[0]?.clientHeight + headerElement[0]?.clientHeight
    : headerElement[0]?.clientHeight || 0;

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="header-tabbar"
      >
        <Tab label="User Form Emulation" value={"1"} />
      </Tabs>
      <Box
        sx={{
          height: `calc(100vh - ${headerHeight}px - 32px)`,
          overflowY: "scroll",
        }}
      >
        <Card
          elevation={0}
          sx={{
            m: 3,
            p: 2,
            border: "1px solid lightgrey",
            borderRadius: "12px",
          }}
        >
          <Box sx={{ flexDirection: "column", display: "flex" }}>
            <Typography variant="h5" sx={{ mb: 3 }}>
              Emulate User
            </Typography>

            <Typography variant="caption" color="#aaa" sx={{ mb: 2 }}>
              Choose user and scope for emulate
            </Typography>

            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="asynchronous-demo"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option: any) =>
                    `${option?.id} - ${option?.email}`
                  }
                  options={userOptions}
                  loading={loading}
                  value={user}
                  onChange={handleUserChange}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      label="User"
                      error={error && error?.user ? true : false}
                      helperText={error && error?.user ? error?.user : ""}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  value={scope}
                  onChange={(event, newValue) => setScope(newValue)}
                  id="Scope"
                  options={["Readonly", "Readwrite"]}
                  getOptionLabel={(option) => option}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderOption={(props, option) => <li {...props}>{option}</li>}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Scope"
                      error={error && error?.scope ? true : false}
                      helperText={error && error?.scope ? error?.scope : ""}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              sx={{ width: "30%" }}
              onClick={handleSubmit}
            >
              Create
            </Button>
          </Box>
        </Card>
        <div style={{ margin: "24px" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{ mb: 2 }}
          >
            <Tab label="My Current Emulations" value="1" />
            <Tab label="All Emulations" value="2" />
          </Tabs>
          {tabValue == "1" ? (
            <Box>
              <MUIDataTable
                title={"My Current Emulations"}
                data={uiUserDataShareScope}
                columns={currEmulationcolumns}
                options={{ ...currEmulationOptions }}
              />
            </Box>
          ) : (
            <Box>
              <MUIDataTable
                title={"All Emulations"}
                data={allUiUserDataShareScope}
                columns={allEmulationcolumns}
                options={{ ...allEmulationOptions }}
              />
            </Box>
          )}
        </div>
        <DeleteUserEmulation
          isDeletePopup={isDelete}
          setIsDelete={setIsDelete}
          currUser={deleteCurrUser}
          uiUserDataShareScope={
            tabValue == "1" ? uiUserDataShareScope : allUiUserDataShareScope
          }
        />
      </Box>
    </>
  );
};

export default FormEmulation;
