import { useEffect, useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import BackDropLoader from "../../components/loader/backDropLoader";

function AdminDashboard() {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const { loading } = useAppSelector((state: any) => state.notificationReducer);
  const localIsBoostrapCalled = localStorage.getItem("isBootstrapCalled");

  const isAdmin = localStorage.getItem("userType");
  // const isAdmin = localStorage.getItem("userType")?.toLocaleLowerCase() === "admin";

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const userData: any = JSON.parse(localStorage.getItem("userData") || "{}");
  }, []);

  return (
    <>
      {isAdmin !== null && isAdmin !== "Admin" ? (
        <Box
          sx={{
            height: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            Admin access required
          </Typography>
        </Box>
      ) : (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            className="header-tabbar"
          >
            <Tab label="Home" value={"1"} />
          </Tabs>

          {value === "1" && (
            <div
              className="mx-auto relative w-full mt-20"
              style={{ maxWidth: "300px", fontSize: "20px" }}
            >
              Welcome to Admin
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AdminDashboard;
