import React from "react";
import { Link } from "react-router-dom";

export default function FooterComponent() {
  return (
    <div className="md:text-left  text-center p-[10px] md:pt-[20px] md:pb-[25px] md:px-8 fixed bottom-0  w-full md:w-[calc(100%_-_240px)] md:flex block items-center justify-between">
      <span className="text-sm text-[#9CA3AF]">
        © 2023 USA Path | All rights reserved
      </span>
      <ul className="flex gap-6 p-0 md:justify-unset justify-center">
        <li className="text-[#9CA3AF] font-medium">
          <Link to="#">Terms & Conditions</Link>
        </li>
        <li className="text-[#9CA3AF] font-medium">
          <Link to="#">Privacy Policy</Link>
        </li>
      </ul>
    </div>
  );
}
