import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

import Verify from "../../pages/verify";
import Formflow from "../../pages/formFlow";
import Refreshflow from "../../pages/refresh";
import AddNewForm from "../../pages/addNewForm";
import UserForms from "../../pages/userForms";

import UserTable from "../../pages/userTable";
import DisableUser from "../../pages/disableUser";
import FormEmulation from "../../pages/formEmulation";

import PublicRoute from "../../components/PublicRoute";
import PrivateRoute from "../../components/PrivateRoute";
import AdminDashboard from "../../components/adminDashboard";
import LayoutComponent from "../../components/layout/layout";
import PublicFormflow from "../../components/public/userForms";
import VerifyLayoutComponent from "../../components/verifyLayout";

import B2BEntities from "../../pages/B2BEntities";
import IframeListener from "../../pages/IframeListener";
import LoginForm from "../../pages/verify/login";
import CodePage from "../../pages/verify/CodePage/CodePage";
import CsvManagement from "../../pages/csvManagement";

import IframePage from "../../pages/iframePage";
import FlowMappings from "../../pages/flowMappings";

function UserRoutes() {
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData") || "{}")
    : null;

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <LoginForm />
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/code"
          element={
            <PublicRoute>
              <CodePage />
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/"
          element={
            <PublicRoute>
              <LoginForm />
            </PublicRoute>
          }
        ></Route>

        <Route
          path="/users"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <UserTable />
              </LayoutComponent>
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/verify"
          element={
            <VerifyLayoutComponent>
              <Verify />
            </VerifyLayoutComponent>
          }
        ></Route>
        <Route path="/iframe-listener" element={<IframeListener />} />

        <Route
          path="/userForm"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <UserForms />
              </LayoutComponent>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/disable-user"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <DisableUser />
              </LayoutComponent>
            </PrivateRoute>
          }
        ></Route>

        <Route
          path="/form"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <Formflow />
              </LayoutComponent>
            </PrivateRoute>
          }
        />
        <Route
          path="/refresh"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <Refreshflow />
              </LayoutComponent>
            </PrivateRoute>
          }
        />
        <Route
          path="/add-new-form"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <AddNewForm />
              </LayoutComponent>
            </PrivateRoute>
          }
        />
        <Route
          path="/b2b-entities"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <B2BEntities />
              </LayoutComponent>
            </PrivateRoute>
          }
        />
        <Route
          path="/csv-management"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <CsvManagement />
              </LayoutComponent>
            </PrivateRoute>
          }
        />
        <Route
          path="/flow-mappings"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <FlowMappings />
              </LayoutComponent>
            </PrivateRoute>
          }
        />

        <Route
          path="/form_emulation"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <FormEmulation />
              </LayoutComponent>
            </PrivateRoute>
          }
        />
        <Route
          path="/public/userForm/:formId"
          element={
            <PrivateRoute>
              <PublicFormflow />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <LayoutComponent>
                <AdminDashboard />
              </LayoutComponent>
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <Navigate to={`${userData?.isVerified ? "/admin" : "/"}`} replace />
          }
        />
        <Route
          path="/iframe"
          element={
            <PrivateRoute>
              <IframePage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default UserRoutes;
