import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CodePage.css";
import { useToken } from "../../../context/TokenContext";
import dotnetApiClient from "../../../config/dotnetApiClient";
import axios from "axios";
import logo from "../../../assets/images/logo.svg";

const MAX_ATTEMPTS = 3;

const CodePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setToken } = useToken();
  const [code, setCode] = useState<string>("");
  const [validationID, setValidationID] = useState<string>(
    location.state?.validationID || ""
  );
  const [email, setEmail] = useState<string>(location.state?.email || "");
  const [attempts, setAttempts] = useState<number>(0);
  const [error, setError] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!validationID) {
      alert("Validation ID is missing. Redirecting to login.");
      navigate("/");
    }
  }, [validationID, navigate]);

  const handleValidationError = (validationError: {
    code: number;
    message: string;
  }) => {
    switch (validationError.code) {
      case 0: // CodeExpired
        setError("The code has expired. Please request a new one.");
        setIsDisabled(true);
        break;
      case 1: // ExceededMaxAttempts
        setError(
          "You have exceeded the maximum number of attempts. Please request a new code."
        );
        setIsDisabled(true);
        break;
      case 2: // AlreadyValidated
        setError("This code has already been used. Please request a new one.");
        setIsDisabled(true);
        break;
      case 3: // InvalidValidationRequest
        setError(
          "Invalid validation request. Please check your code and try again."
        );
        break;
      case 4: // CodeMismatch
        setAttempts((prev) => prev + 1);
        setError("The code you entered is incorrect. Please try again.");
        break;
      default:
        setError("An unexpected error occurred. Please try again.");
        break;
    }
  };

  const onhandleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (attempts >= MAX_ATTEMPTS && !isDisabled) {
      setError(
        "You have exceeded the maximum number of attempts. Please request a new code."
      );
      setIsDisabled(true);
      return;
    }

    const requestBody = { code, validationID };

    try {
      const baseURL = dotnetApiClient().defaults.baseURL;
      const response = await axios.post(
        `${baseURL}/auth/passwordless/validate`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200 && response.data.isValid) {
        const data = response.data;
        const token = data.token;
        const user = data.user;
        const billingEntityId = user.billingEntityId;
        localStorage.setItem("userType", response.data.user?.userType);
        localStorage.setItem("lawyerData", JSON.stringify(data));

        if (billingEntityId) {
          localStorage.setItem("billingEntityId", billingEntityId);
        }

        if (token && user) {
          localStorage.setItem("accessToken", token);
          localStorage.setItem("userData", JSON.stringify(user));
          setToken(token);
        }
        navigate("/admin");
      } else {
        handleValidationError(response.data.validationError);
      }
    } catch (error: any) {
      if (error.response?.data?.validationError) {
        handleValidationError(error.response.data.validationError);
      } else {
        setError("An error occurred. Please try again.");
      }
    }
  };

  const handleResendCode = async () => {
    try {
      const baseURL = dotnetApiClient().defaults.baseURL;
      const response = await axios.post(
        `${baseURL}/auth/passwordless/initiate`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setAttempts(0);
        setIsDisabled(false);
        setError("");
        setCode("");

        setValidationID(response.data.validationID);
        alert("A new verification code has been sent to your email.");
      } else {
        setError("Failed to resend the code. Please try again.");
      }
    } catch (error: any) {
      setError("An error occurred while resending the code. Please try again.");
    }
  };

  const handleChangeEmail = () => {
    navigate("/");
  };

  return (
    <div className="code-page-container">
      <div className="form-section">
        <div className="form-container">
          <img
            src={logo}
            alt="Law Firm Logo"
            style={{ width: "60%", margin: "auto", display: "block" }}
          />
          <h1 style={{ display: "flex", justifyContent: "center" }}>
            Welcome to Admin UI
          </h1>
          <p>
            Email: {email}
            <span onClick={handleChangeEmail} className="change-email-link">
              Change Email Address
            </span>
          </p>
          <form onSubmit={onhandleLogin}>
            <label htmlFor="code">Code</label>
            <input
              type="text"
              id="code"
              name="code"
              placeholder="Enter code received on email"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              disabled={isDisabled}
            />
            {error && <span className="error-message">{error}</span>}
            {!isDisabled && (
              <button
                type="submit"
                disabled={isDisabled}
                style={{ background: "#2557b3" }}
              >
                Login to your account
              </button>
            )}
          </form>
          {isDisabled && (
            <button
              onClick={handleResendCode}
              style={{ background: "#111827", marginTop: "10px" }}
            >
              Resend Code
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodePage;
