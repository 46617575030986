import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
  notificationClear,
  setUploadLoading,
} from "../slices/notificationSlice";
import apiClient from "../../config/apiClient";
import {
  setAllDocumentData,
  setProgressData,
  setSummaryAllData,
  setUploadedDocument,
} from "../slices/documentSlice";
import dotnetApiClient from "../../config/dotnetApiClient";
import envConfig from "../../config/config";
import { anonymousUser, getApiUrl, handleLogout } from "../../helper/service";
import axios from "axios";
import { getCurrentFaqsData } from "../slices/flowFormSlice";

export const getAllDocumentData: any = createAsyncThunk(
  "getAllDocumentData",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`UiEvidenceDocumentType/`);
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setAllDocumentData(response?.data));
        // dispatch(notificationSuccess("Get form Data "));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const updateAssociation: any = createAsyncThunk(
  "updateAssociation",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().put(
        `UiEvidenceDocumentType/typeText/${_request?.id}`,
        `${_request?.name || ""}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(setLoading(false));

      if (response?.data) {
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(getAllDocumentData());
        dispatch(notificationSuccess("Name Updated successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const addAssociation: any = createAsyncThunk(
  "addAssociation",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `UiEvidenceDocumentType`,
        _request
      );
      dispatch(setLoading(false));

      if (response?.data) {
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(getAllDocumentData());
        dispatch(notificationSuccess("Added successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const uploadDocuments: any = createAsyncThunk(
  "uploadDocuments",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setUploadLoading(true));
      const formData = new FormData();
      _request?.files?.map((val: any) => {
        formData.append("files", val);
      });
      const response = await dotnetApiClient().post(
        `UiEvidenceDocument/files/upload/${_request.documentTypeId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Set the content type for FormData
          },
        }
      );

      if (response?.data) {
        dispatch(setUploadLoading(false));
        dispatch(
          getUpoadedDocuments({
            documentTypeId: _request?.documentTypeId || "",
          })
        );
        dispatch(getAllDocumentData());
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(notificationSuccess("Added successfully"));
      } else {
        dispatch(setLoading(false));
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getUpoadedDocuments: any = createAsyncThunk(
  "getUpoadedDocuments",
  async (_request: any, { dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const response = await dotnetApiClient().get(
        `UiEvidenceDocument/documentType/${_request.documentTypeId}`
      );
      // dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setUploadedDocument(response?.data));
        // dispatch(notificationSuccess("Get form Data "));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const deleteDocument: any = createAsyncThunk(
  "deleteDocument",
  async (_request: any, { dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const response = await dotnetApiClient().delete(
        `/UiEvidenceDocument/${_request.id}`
      );
      // dispatch(setLoading(false));

      if (response) {
        dispatch(
          getUpoadedDocuments({
            documentTypeId: _request?.documentTypeId || "",
          })
        );
        dispatch(getAllDocumentData());
        dispatch(notificationSuccess("Document deleted successfully"));
      } else {
        dispatch(notificationFail("Something went wrong"));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const downloadDocument: any = createAsyncThunk(
  "downloadDocument",
  async (_request: any, { dispatch }) => {
    try {
      const baseUrl = getApiUrl();
      fetch(`${baseUrl}/UiEvidenceDocument/files/${_request?.filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
        .then((response: any) => response.blob())
        .then((image: any) => {
          const image_url = URL.createObjectURL(image);
          return image_url;
        })
        .then((url: any) => {
          let a = document.createElement("a");
          a.href = url;
          a.download = "image.jpg";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const updateOrder: any = createAsyncThunk(
  "updateOrder",
  async (_request: any, { dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const response = await dotnetApiClient().post(
        `/UiEvidenceDocument/documentOrder`,
        {
          ..._request.newOrder,
        }
      );
      // dispatch(setLoading(false));

      if (response?.status == 200) {
        dispatch(
          getUpoadedDocuments({
            documentTypeId: _request?.documentTypeId || "",
          })
        );
        // dispatch(setAllDocumentData(response?.data?.data));
        dispatch(notificationSuccess("Order updated successfully"));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// Wordpress get FAQ API call
export const getFaqList: any = createAsyncThunk(
  "getFaqList",
  async (_request: any, { dispatch }) => {
    try {
      // dispatch(setLoading(true));
      const { faqData } = _request;
      // const faqs = faqData.join(", ").replace(/,([^,]*)$/, '$1');
      const faqs = faqData.join(",");

      const response = await dotnetApiClient().get(`/faq?faqIds=${faqs}`);
      if (response?.status == 200) {
        // dispatch(setLoading(false));
        dispatch(getCurrentFaqsData(response?.data));
      } else {
        // dispatch(setLoading(false));
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// Progress bar API call 16-11-2023
export const getProgressData: any = createAsyncThunk(
  "getProgressData",
  async (_request, { dispatch }) => {
    try {
      if (anonymousUser()) {
        return true;
      }
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(`UiProgress/Section`);

      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setProgressData(response?.data));
      } else {
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          "We have detected an error. Please contact support for assistance."
        )
      );
    }
  }
);

// Summary API call 20-11-2023
export const getSummaryData: any = createAsyncThunk(
  "getSummaryData",
  async (_request, { dispatch }) => {
    try {
      if (anonymousUser()) {
        dispatch(setLoading(false));
        return true;
      }
      dispatch(setLoading(true));
      const response = await dotnetApiClient().get(
        `UiProgress/SectionSummaryGroup`
      );
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setSummaryAllData(response?.data));
      } else {
        dispatch(setLoading(false));
        dispatch(
          notificationFail(
            response?.data?.message ||
              "We have detected an error. Please contact support for assistance."
          )
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(
        notificationFail(
          "We have detected an error. Please contact support for assistance."
        )
      );
    }
  }
);
