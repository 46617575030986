import { Tab, Tabs } from "@mui/material";
import FormFlow from "../../components/formFlow";
import { useState } from "react";

const Formflow = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="header-tabbar"
      >
        <Tab label="Form" value={"1"} />
      </Tabs>
      {value === "1" && (
        <div className="relative overflow-x-auto h-[calc(100vh_-_130px)] md:h-[calc(100vh_-_155px)] p-2">
          <FormFlow />
        </div>
      )}
    </>
  );
};

export default Formflow;
