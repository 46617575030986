import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useNavigate } from "react-router";
import { anonymousUser } from "../../helper/service";

function PrivateRoute(props: any) {
  const { children } = props;
  const { logout } = useAuth0();
  const navigate = useNavigate();

  if (window.self !== window.top) {
    const urlParams = new URLSearchParams(window.location.search);

    const token = urlParams.get("token");

    if (token) {
      localStorage.setItem("iframeAccessToken", token);
    }
  }

  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  !accessToken && navigate("/");

  const userData: any = localStorage.getItem("userData")
    ? localStorage.getItem("userData")
    : null;
  const user_data = JSON.parse(userData);

  if (anonymousUser()) {
    return children;
  }

  if (!accessToken) {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  if (!user_data?.isVerified) {
    return <Navigate to="/verify" />;
  }

  return accessToken && userData ? children : <Navigate to="/admin" />;
}

export default PrivateRoute;
