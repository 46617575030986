import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
} from "@mui/material";

export default function CsvReader({ headers, csvArray }: any) {
  const [csvFile, setCsvFile] = useState<any>(null);
  // const [csvArray, setCsvArray] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [tempHeaders, setTempHeaders] = useState<any>(null);

  //   const processCSV = (str: any, delim = '\t') => {
  //     const headers = str.slice(0, str.indexOf('\n')).split(delim);
  //     const rows = str.slice(str.indexOf('\n') + 1).split('\n');

  //     const newArray = rows.map((row: any) => {
  //       const values = row.split(delim);
  //       const eachObject = headers.reduce((obj: any, header: any, i: number) => {
  //         obj[header] = values[i];
  //         return obj;
  //       }, {});
  //       return eachObject;
  //     });

  //     setTempHeaders(headers);
  //     setCsvArray(newArray);
  //   };

  // const submit = () => {
  //   const file = csvFile;
  //   const reader = new FileReader();

  //   reader.onload = function (e: any) {
  //     const text = e.target.result;
  //     processCSV(text);
  //   };

  //   reader.readAsText(file);
  // };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <form id="csv-form">
      {/* <input
        type="file"
        accept=".csv"
        id="csvFile"
        onChange={(e: any) => setCsvFile(e.target.files[0])}
      />
      <br />
      <button
        onClick={(e) => {
          e.preventDefault();
          if (csvFile) submit();
        }}
      >
        Submit
      </button>
      <br />
      <br /> */}
      {csvArray?.length > 0 && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers?.length > 0 &&
                  headers?.map((data: any, index: any) => (
                    <TableCell sx={{ fontWeight: "bold" }}>{data}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {csvArray
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item: any, i: number) => (
                  <TableRow key={i}>
                    {Object.values(item).map((itemData: any, index: number) => (
                      <TableCell>{itemData || "--"}</TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  count={csvArray?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}
    </form>
  );
}
