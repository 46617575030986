import React, { useState, useEffect } from "react";
import { useNodesState } from "reactflow";
import { Tabs, Tab } from "@mui/material";
import "reactflow/dist/style.css";
import "./index.css";
import { useDispatch } from "react-redux";
import {
  createFormName,
  getAllForms,
  getBillingEntities,
  getFormJsonData,
} from "../../store/thunk/formThunk";
import FormVersion from "./FormVersion";
import { useAppSelector } from "../../store/store";

interface BillingEntityProps {
  billingId: string;
  billingName: string;
  billingType: string;
  id: number;
}

const FlowMappings = () => {
  const dispatch: any = useDispatch();

  const [uiNodes, setUiNodes] = useNodesState<any>([]);

  const [nodeData, setNodeData] = useState<any>({});
  const [formVersion, setFormVersion] = useState("");
  const [formVersionList, setFormVersionList] = useState([]);
  const [formName, setFormName] = useState("");
  const [formNameList, setFormNameList] = useState([]);
  const [tabValue, setTabValue] = useState(1);
  const [mappingFormData, setMappingFormData] = useState<any>({});

  const [selectedBillingEntityId, setSelectedBillingEntityId] = useState<
    number | string
  >(0);

  const loading = useAppSelector(
    (state: any) => state.notificationReducer.loading
  );

  const { allForms, formJsonData, billingEntities } = useAppSelector(
    (state: any) => state?.formReducer
  );

  const [formState, setFormState] = useState<any>({
    flow: 0,
    version: "",
    billingEntityId: -1,
  });

  const defaultBillingEntity = billingEntities?.find(
    (entity: BillingEntityProps) =>
      entity.billingName.toLowerCase() === "lawfirm"
  );

  // useEffect(() => {
  //   if (defaultBillingEntity) {
  //     setFormState({
  //       ...formState,
  //       billingEntityId: defaultBillingEntity.id,
  //     });
  //   }
  // }, [defaultBillingEntity]);

  useEffect(() => {
    dispatch(getBillingEntities());
  }, []);

  useEffect(() => {
    if (billingEntities && billingEntities.length > 0) {
      var defaultBillingEntity = billingEntities[0];

      setFormState({
        ...formState,
        billingEntityId: defaultBillingEntity.id,
      });
    }
  }, [billingEntities]);

  useEffect(() => {
    if (formState?.billingEntityId && formState.billingEntityId != -1) {
      dispatch(getAllForms(formState?.billingEntityId));
    }
  }, [formState?.billingEntityId]);

  useEffect(() => {
    // Use Set to store unique titles
    const uniqueTitlesSet = new Set();

    // Filter out unique titles
    const uniqueTitlesArray = allForms.filter((obj: any) => {
      // Check if the title is not in the set
      if (!uniqueTitlesSet.has(obj.title)) {
        // Add the title to the set
        uniqueTitlesSet.add(obj.title);
        // Include the object in the result
        return true;
      }
      // Title is already in the set, exclude the object
      return false;
    });

    setFormNameList(uniqueTitlesArray);
    if (allForms?.length) {
      const versionList = allForms.filter(
        (val: any) => val.title == formState?.flow?.title
      );

      setFormVersionList(versionList);
      // setFormState({
      //   ...formState,
      //   flow: allForms[0].id,
      //   version: versionList[versionList.length - 1],
      // });
    } else {
      setFormVersionList([]);
      setFormState({ ...formState, version: "" });
      setNodeData({});
      setUiNodes([]);
    }
  }, [allForms]);

  useEffect(() => {
    if (formState?.flow?.title) {
      const versionList = allForms.filter(
        (val: any) => val.title == formState?.flow?.title
      );

      setFormVersionList(versionList);
      setFormState({
        ...formState,
        version: versionList[versionList.length - 1],
      });
    }
  }, [formState?.flow?.title, formState?.billingEntityId]);

  useEffect(() => {
    if (formState?.version?.id) {
      dispatch(getFormJsonData({ id: formState?.version?.id }));
    }
  }, [formState?.version?.id]);

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const addNewForm = async (billingEntityId: number, data: any) => {
    let formDetails = {
      title: data,
      flowJson: JSON.stringify({}),
      uiJson: JSON.stringify({}),
    };

    let createRequest = {
      billingEntityId: billingEntityId,
      formDetails: formDetails,
    };

    await dispatch(createFormName(createRequest));
  };

  const formInputChange = (event: any) => {
    setMappingFormData({});
    setFormState((prev: any) => ({
      ...prev,
      [event.target.name]: event.target.value,
      ...(event.target.name == "flow" && {
        version: {},
      }),
    }));
  };

  useEffect(() => {
    if (Object.keys(formJsonData).length > 0) {
      setNodeData(
        formJsonData?.flowJson ? JSON.parse(formJsonData?.flowJson) : ""
      );
      setUiNodes(formJsonData?.uiJson ? JSON.parse(formJsonData?.uiJson) : "");
    }
  }, [formJsonData]);

  return (
    <div className="md:p-8 p-4">
      <div>
        <FormVersion
          billingEntities={billingEntities}
          formState={formState}
          setFormState={setFormState}
          formInputChange={formInputChange}
          formNameList={formNameList}
          formVersionList={formVersionList}
          addNewForm={addNewForm}
          formVersion={formVersion}
          formName={formName}
          setFormNameList={setFormNameList}
        />
      </div>
      <div>
        {/* <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          centered
          sx={{ mb: 2 }}
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <Tab
            sx={{
              background: tabValue === 1 ? "#2563eb" : "#ccc",
              color: tabValue === 1 ? "white" : "#000",
              fontWeight: "700",
              mx: "10px",
              borderRadius: "30px",
            }}
            label="JSON"
            value={1}
          />
          <Tab
            sx={{
              background: tabValue === 2 ? "#2563eb" : "#ccc",
              color: tabValue === 2 ? "white" : "#000",
              fontWeight: "700",
              mx: "10px",
              borderRadius: "30px",
            }}
            label="Render form"
            value={2}
          />
        </Tabs> */}
        {tabValue === 1 ? <></> : tabValue === 2 ? <></> : null}
      </div>
    </div>
  );
};

export default FlowMappings;
