import { Navigate } from "react-router";

function PublicRoute(props: any) {
  const { children } = props;
  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;
  const userData = localStorage.getItem("userData")
    ? localStorage.getItem("userData")
    : null;
  const user_data = JSON.parse(userData || "{}");

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  if (userData) {
    if (!user_data?.isVerified) {
      return <Navigate to="/verify" />;
    }
    return accessToken && userData ? <Navigate to="/admin" /> : children;
  }
  return children;
}

export default PublicRoute;
