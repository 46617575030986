import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userDetails: {},
  authConfig: {
    authData: {
      domain: "",
      audience: "",
      clientId: "",
    },
    stripeData: {
      productPaymentLink: "",
    },
    intercomData: {
      appId: "",
    },
    hotjarData: {
      siteId: "",
      version: "",
    },
    mixPanelData: {
      projectId: "",
    },
  },
  verifyAuth: {},
};

export const authSlice = createSlice({
  name: "authuser",
  initialState,
  reducers: {
    // setUserDetails: (state, action) => ({
    //   ...state,
    //   userDetails: action.payload,
    // }),

    setVerifyAuth: (state, action) => ({
      ...state,
      verifyAuth: action.payload,
    }),
  },
});

export const { setVerifyAuth } = authSlice.actions;
export default authSlice.reducer;
