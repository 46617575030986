import "./App.css";
import PageRoutes from "./routes/authPages";
import { useAppDispatch, useAppSelector } from "./store/store";
import SnackBarComponent from "./components/snackBar";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import { anonymousUser, generateGuid } from "./helper/service";

function App() {
  const dispatch = useAppDispatch();
  const { authConfig } = useAppSelector((state: any) => state.authReducer);

  const { progressData } = useAppSelector(
    (state: any) => state.documentReducer
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var navigationEntries = window.performance.getEntriesByType("navigation");

    if (navigationEntries.length > 0) {
      var navigationType = navigationEntries[0] as PerformanceNavigationTiming;

      // back_forward value indicates a duplicate tab
      if (navigationType.type === "back_forward") {
        let sessionID = sessionStorage.getItem("Usapath-Session-Id");
        sessionID = generateGuid();
        sessionStorage.setItem("Usapath-Session-Id", sessionID);
      }
    }

    setIsLoading(false);
  }, []);

  {
    /* ----- On refresh update session id  ----- */
  }
  // useEffect(() => {
  //   const tabsOpen = localStorage.getItem("tabsOpen");
  //   let sessionID = sessionStorage.getItem("Usapath-Session-Id");

  //   if (tabsOpen == null) {
  //     localStorage.setItem("tabsOpen", "1");
  //   } else {
  //     sessionID = generateGuid();
  //     sessionStorage.setItem("Usapath-Session-Id", sessionID);
  //     localStorage.setItem(
  //       "tabsOpen",
  //       (parseInt(tabsOpen) + parseInt("1")).toString()
  //     );
  //   }

  //   // define decrement counter part
  //   window.onunload = function (e) {
  //     const newTabCount = localStorage.getItem("tabsOpen");
  //     if (newTabCount !== null) {
  //       localStorage.setItem(
  //         "tabsOpen",
  //         (parseInt(newTabCount) - 1).toString()
  //       );
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : null;
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData") || "{}")
      : null;
    if (authConfig?.intercomData?.appId) {
      if (
        (accessToken && userData && Object.keys(userData).length > 0) ||
        anonymousUser()
      ) {
        let general_eligibility_percentage;
        let getting_to_know_you_percentage;
        let disclaimer_percentage;
        let employment_info_percentage;
        let evidence_engine_percentage;
        let financial_info_percentage;
        let immigration_travel_percentage;
        let special_forms_percentage;
        let miscllaneous_percentage;
        let printsign_percentage;
        let personal_information_percentage;

        if (progressData && progressData?.length) {
          progressData?.length &&
            progressData?.map((data: any, index: number) => {
              if (data?.sectionKey === "General_Eligibility") {
                general_eligibility_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Getting_to_Know_you") {
                getting_to_know_you_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Disclaimer") {
                disclaimer_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "EmploymentInfo") {
                employment_info_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Evidence_Engine") {
                evidence_engine_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Financial_Info") {
                financial_info_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Immigration&Travel") {
                immigration_travel_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Special_Forms") {
                special_forms_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Miscllaneous") {
                miscllaneous_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Printsign") {
                printsign_percentage = data?.percentComplete;
              }
              if (data?.sectionKey === "Personal_Information") {
                personal_information_percentage = data?.percentComplete;
              }
            });
        }
        // Update window.intercomSettings with user data
        (window as any).intercomSettings = {
          ...(window as any).intercomSettings,
          ...{
            email: userData?.email,
            name: userData?.name,
            progress_section_general_eligibility:
              general_eligibility_percentage,
            progress_section_getting_to_know_you:
              getting_to_know_you_percentage,
            progress_section_disclaimer: disclaimer_percentage,
            progress_section_employment_info: employment_info_percentage,
            progress_section_evidence_engine: evidence_engine_percentage,
            progress_section_financial_info: financial_info_percentage,
            progress_section_immigration_travel: immigration_travel_percentage,
            progress_section_special_forms: special_forms_percentage,
            progress_section_miscllaneous: miscllaneous_percentage,
            progress_section_printsign: printsign_percentage,
            progress_section_personal_information:
              personal_information_percentage,
          },
          app_id: authConfig?.intercomData?.appId,
        };
      }
    }
  }, [authConfig, progressData]);

  if (isLoading) {
    return (
      <div className="flex h-screen justify-center items-center">
        <CircularProgress size={24} />
      </div>
    );
  } else {
    return (
      <div>
        <PageRoutes />
        <SnackBarComponent />
      </div>
    );
  }
}

export default App;
