import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Switch,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  CircularProgress,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ReactJson from "react-json-view";
import { useAppDispatch, useAppSelector, RootState } from "../../store/store";
import {
  getSingleUser,
  getUserPDFDownload,
  getEvidencePacketDownload,
  getHistoryChanges,
  getUiFormPayloadData,
} from "../../store/thunk/userThunk";
import { setSingleUserData } from "../../store/slices/userSlice";
import SideLogo from "../../assets/images/logo-white.png";
import { formatDateTime, logErrorFunction } from "../../helper/service";
import dotnetApiClient from "../../config/dotnetApiClient";
import { setLoading } from "../../store/slices/notificationSlice";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccordionComponent = (props: any) => {
  const { uiFormIdList, userId, dispatch } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const [uiFormPayLoadData, setUiFormPayLoadData] = useState<string | null>(
    null
  );

  const handleAccoridanClick = async (data: any) => {
    setExpanded(!expanded);
    if (expanded) {
      return true;
    } else {
      const userData = {
        userId,
        uiFormDataId: data?.id,
      };
      try {
        const res = await dispatch(getUiFormPayloadData(userData));
        const payloadData = JSON.stringify(res?.payload?.data);
        setUiFormPayLoadData(payloadData);
      } catch (error) {
        logErrorFunction("Error fetching UI form payload data:" + error);
        throw error;
      }
    }
  };

  const renderAccordian = (data: any) => {
    return (
      <Accordion key={data}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          onClick={() => handleAccoridanClick(data)}
        >
          {formatDateTime(data?.lastUpdateDtUtc)}
        </AccordionSummary>
        <AccordionDetails>
          {uiFormPayLoadData && (
            <div
              className="jsonViewer"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <ReactJson
                src={JSON.parse(uiFormPayLoadData)}
                enableClipboard={false}
                displayDataTypes={false}
                displayObjectSize={false}
              />
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  return <div>{uiFormIdList?.map((data: any) => renderAccordian(data))}</div>;
};

const ViewComponent = (props: any) => {
  const {
    isViewPopup,
    setIsView,
    userId,
    setDeleteState,
    onUserDisabled,
    onChangeField,
  } = props;
  const dispatch = useAppDispatch();
  const { singleUser } = useAppSelector(
    (state: RootState) => state.userReducer
  );
  const { loading } = useAppSelector(
    (state: RootState) => state.notificationReducer
  );
  const [value, setValue] = useState(0);
  const [historyMessage, setHistoryMessage] = useState<string>("");
  const [uiFormIdList, setUiFormIdList] = useState([]);
  const [singleUserData, setUserData] = useState<any>({ userType: "" });
  const [isIframe] = useState<boolean>(window.self !== window.top);
  const [clientData, setClientData] = useState<any>();
  const [selectedCaseType, setSelectedCaseType] = useState(0);
  const [packetHistory, setPacketHistory] = useState<any>([]);
  const [selectedCaseTypeFormList, setSelectedCaseTypeFormList] = useState([]);

  const pdfUrlList = [
    "i-864",
    "i-130",
    "i-130a",
    "i-131",
    "i-485",
    "i-765",
    "g-1145",
    "i-693",
    "i-864a-household-member-1",
    "i-864a-household-member-2",
    "i-864-joint-sponsor",
    "i-864w",
    "g-28-petitioner",
    "g-28-immigrant",
  ];
  const handleCaseType = (e: any) => {
    setSelectedCaseType(e?.target?.value);
  };
  const renderMenuItem = () => {
    return clientData?.formsForCaseTypes?.map((list: any, index: number) => (
      <MenuItem key={index} value={list.caseId}>
        {list.caseName}
      </MenuItem>
    ));
  };

  useEffect(() => {
    dispatch(getSingleUser({ userId: userId })).then((res: any) => {
      setSelectedCaseType(res?.payload?.data?.formsForCaseTypes?.[0]?.caseId);
      setSelectedCaseTypeFormList(
        res?.payload?.data?.formsForCaseTypes?.[0]?.formNames
      );
      setClientData(res?.payload?.data);
    });
    return () => {
      dispatch(setSingleUserData({}));
      setHistoryMessage("");
    };
  }, []);

  useEffect(() => {
    if (singleUser) {
      setUserData(singleUser);
    }
  }, [singleUser]);

  const handlePdfClick = (pdfName: any) => {
    const userFormData = {
      userId,
      formName: pdfName,
    };
    dispatch(getUserPDFDownload(userFormData));
  };

  const handleDownlaodPacket = () => {
    const userFormData = {
      userId,
      caseId: selectedCaseType,
    };
    dispatch(getEvidencePacketDownload(userFormData));
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);

    let tempUiFormIdList: any = [];

    if (newValue === 1) {
      dispatch(getHistoryChanges({ userId })).then(async (res: any) => {
        if (res?.payload?.data?.length > 0) {
          res?.payload?.data?.map((data: any) => {
            tempUiFormIdList.push(data);
          });

          setUiFormIdList(tempUiFormIdList);
        } else {
          setHistoryMessage("No history found");
        }
      });
    }
  };

  const formLabels: { [key: string]: string } = {
    "i-864a-household-member-1": "I864a Household Member of Petitioner",
    "i-864a-household-member-2": "I864a Household Member of Joint Sponsor",
    "i-864-joint-sponsor": "I864 for Joint Sponsor",
    "g-28-immigrant": "G28 for Immigrant",
    "g-28-petitioner": "G28 for Petitioner",
  };
  const handleDownloadPacketFromHistory = async (_request: any) => {
    const { versionId } = _request;
    setLoading(true);

    try {
      const response = await dotnetApiClient().get(
        `/evidencePacket/versions/${versionId}/download`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Packet_${versionId}_${new Date()
          .toISOString()
          .replace(/[:.]/g, "_")}.pdf`;
        link.click();
      }
    } catch (error: any) {
      logErrorFunction("Error downloading PDF:" + error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const getPacketHistory = async () => {
      try {
        const axiosInstance = dotnetApiClient();
        const response = await axiosInstance.get(
          `/evidencePacket/user/${userId}/case/${selectedCaseType}/versions`
        );

        if (response.data) {
          setPacketHistory(response?.data);
        } else {
          logErrorFunction("Failed to delete client");
        }
      } catch (error: any) {
        logErrorFunction("Error deleting client:" + error);
      }
    };
    getPacketHistory();
    const updatedPDFList: any = clientData?.formsForCaseTypes?.find(
      (x: any) => x?.caseId === selectedCaseType
    );
    setSelectedCaseTypeFormList(updatedPDFList?.formNames);
  }, [selectedCaseType, userId]);
  return (
    <>
      <Dialog
        open={isViewPopup}
        onClose={() => setIsView(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-view-user"
      >
        {loading && (
          <div className="loader_dialog">
            {!isIframe && (
              <img alt="SIdebar_logo" src={SideLogo} className="mb-3" />
            )}
            <CircularProgress sx={{ color: "#fff" }} />
            <span className="mt-3 text-white">Getting things ready...</span>
          </div>
        )}

        <>
          <DialogTitle id="user-details-dialog-title">User Details</DialogTitle>
          <DialogContent>
            <div className="userViewDetail">
              <div className="basicDetails">
                <div style={{ marginBottom: "10px" }}>
                  <strong>Email:</strong> {singleUserData?.auth0Email || ""}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Nickname:</strong>{" "}
                  {singleUserData?.auth0Nickname || ""}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Name:</strong> {singleUserData?.auth0Name || ""}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>User Type:</strong> {singleUserData?.userType || ""}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Progress %:</strong>{" "}
                  {singleUserData?.progressPercentage || ""}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Verified: </strong>
                  {singleUserData.hasOwnProperty("isVerified") &&
                    (singleUserData?.isVerified ? (
                      <span className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-300 dark:text-green-900">
                        Verified
                      </span>
                    ) : (
                      <span className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-300 dark:text-yellow-900">
                        Un verified
                      </span>
                    ))}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Last login at:</strong>{" "}
                  {formatDateTime(singleUserData?.lastLoginDtUtc) || ""}
                </div>
                <div style={{ marginBottom: "10px" }}></div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>Disabled: </strong>
                  <Switch
                    checked={singleUserData?.isDisabled || false}
                    onChange={(event: any) => onUserDisabled(userId, event)}
                  />
                </div>
                <div style={{ marginBottom: "10px" }}>
                  <strong>User Type: </strong>
                  <Select
                    size="small"
                    name="showUserType"
                    onChange={(e: any) => onChangeField(e, userId)}
                    value={singleUserData?.userType}
                  >
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                  </Select>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 0,
                  }}
                >
                  <label style={{ fontWeight: "700" }}>
                    Select Case Type:{" "}
                  </label>
                  <FormControl sx={{ m: 1, minWidth: 100 }}>
                    <InputLabel id="demo-simple-select-label">
                      Case Type
                    </InputLabel>
                    <Select
                      labelId="caseTypeSelection"
                      id="caseTypeSelection"
                      value={selectedCaseType}
                      label="Case Type"
                      name="CaseType"
                      onChange={(e: any) => handleCaseType(e)}
                    >
                      {renderMenuItem()}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Forms: </strong>
                <div>
                  {selectedCaseTypeFormList?.map((urls: string) => (
                    <Button
                      key={urls}
                      onClick={() => handlePdfClick(urls)}
                      sx={{
                        px: "10px",
                        py: "4px",
                        background: "#2557b3",
                        borderRadius: "8px",
                        mx: "6px",
                        my: "2px",
                        minWidth: "80px",
                        fontSize: "12px",
                        color: "#fff",
                        ":hover": {
                          backgroundColor: "rgba(37, 87, 179, 0.9)",
                        },
                      }}
                    >
                      {formLabels[urls] || urls}
                    </Button>
                  ))}
                </div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Evidence packet: </strong>
                <div>
                  <Button
                    onClick={() => handleDownlaodPacket()}
                    sx={{
                      px: "15px",
                      py: "8px",
                      background: "#2557b3",
                      borderRadius: "10px",
                      mx: "10px",
                      my: "4px",
                      minWidth: "100px",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "rgba(37, 87, 179, 0.9)",
                      },
                    }}
                  >
                    Download Packet
                  </Button>
                </div>
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Packet history: </strong>
                {packetHistory?.length ? (
                  packetHistory?.map((p: any) => (
                    <div className="mb-2">
                      <Button
                        onClick={() =>
                          handleDownloadPacketFromHistory({ versionId: p?.id })
                        }
                        sx={{
                          mr: "10px",
                          minWidth: "80px",
                          fontSize: "12px",
                          fontWeight: 600,
                          color: "#2557b3",
                        }}
                      >
                        Download
                      </Button>
                      {formatDateTime(p?.uploadedOnDtUtc)}
                    </div>
                  ))
                ) : (
                  <p>No history found</p>
                )}
              </div>
              <div style={{ marginBottom: "10px", marginTop: 20 }}>
                <strong>User Progress and Values: </strong>
                <Box sx={{ width: "100%" }}>
                  <Box>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Progress" {...a11yProps(0)} />
                      <Tab label="User Values" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <div
                      className="jsonViewer"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <ReactJson
                        src={JSON.parse(singleUserData?.progress || "{}")}
                        enableClipboard={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                      />
                    </div>
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <AccordionComponent
                      uiFormIdList={uiFormIdList}
                      userId={userId}
                      dispatch={dispatch}
                    />

                    {historyMessage && (
                      <Typography sx={{ fontSize: "18px", color: "#aaa" }}>
                        {historyMessage}
                      </Typography>
                    )}
                  </CustomTabPanel>
                </Box>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setDeleteState(userId)}
              sx={{ backgroundColor: "#d63b3b !important" }}
            >
              Delete User
            </Button>
            <Button onClick={() => setIsView(false)}>Close</Button>
          </DialogActions>
        </>
      </Dialog>
    </>
  );
};

export default ViewComponent;
