import { useEffect, useState } from "react";
import TableComponent from "../../components/table/sampleTable";
import { useAppDispatch } from "../../store/store";
import { getUserList } from "../../store/thunk/userThunk";

import { Tab, Tabs } from "@mui/material";
import { TabPanel } from "@material-tailwind/react";

const UserTable = () => {
  const dispatch: any = useAppDispatch();
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    dispatch(getUserList());
  }, []);

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="header-tabbar"
      >
        <Tab label="Users" value={"1"} />
      </Tabs>

      {value === "1" && (
        <div className="md:p-8 p-4 pt-0">
          <TableComponent />
        </div>
      )}
    </>
  );
};

export default UserTable;
