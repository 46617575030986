import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import "../CodePage/CodePage.css";
import FooterComponent from "../../../components/footer";
import logoUrl from "../../../assets/images/logo.svg";
import dotnetApiClient from "../../../config/dotnetApiClient";
import axios from "axios";

const LoginForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const requestBody = { email };
    try {
      const baseURL = dotnetApiClient().defaults.baseURL;
      const response = await axios.post(
        `${baseURL}/auth/passwordless/initiate`,
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.status === 200) {
        const data = response?.data;
        const validationID = data?.validationID || "";
        const userPersona = data?.userPersona || "";
        navigate("/code", { state: { email, validationID, userPersona } });
      } else {
        alert("Failed to initiate authentication. Please try again.");
      }
    } catch (error: any) {
      console.error(
        "Error initiating authentication:" + error?.response
          ? error?.response?.data
          : error?.message
      );
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = e.target.value;
    setEmail(emailInput);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailInput)) {
      setError("Please enter a valid email address.");
    } else {
      setError("");
    }
  };

  return (
    <div className="code-page-container">
      <div className="form-section">
        <div className="form-container">
          <div style={{ textAlign: "center", marginBottom: 50 }}>
            <img
              src={logoUrl}
              alt="Law Firm Logo"
              style={{ width: "60%", margin: "auto", display: "block" }}
            />
            <h1>Welcome to Admin UI</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <label htmlFor="email">Enter email address to login</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              required
            />
            {error && <span className="error-message">{error}</span>}
            <button
              type="submit"
              disabled={loading || error !== ""}
              className="btn_verification"
            >
              {loading ? "Loading..." : "Get Verification Code"}
            </button>
          </form>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
};

export default LoginForm;
