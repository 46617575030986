import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CsvReader from "./csv_preview";

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store/store";
import {
  downloadCSV,
  downloadVisibilityConditionRef,
  getFlowMappingHistory,
  getVcrHistory,
  uploadCSV,
  uploadVisibilityConditionRef,
} from "../../store/thunk/formThunk";
import CSVPreview from './csv_preview';

import ReactJson from "react-json-view";
import History from "./History";
import BackDropLoader from "../../components/loader/backDropLoader";
import { setLoading } from "../../store/slices/notificationSlice";

const CsvManagement = () => {
  const { loading } = useAppSelector(
    (state: RootState) => state.notificationReducer
  );

  const [value, setValue] = useState("1");
  const [selectedCsv, setSelectedCsv] = useState("CSV");
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFileError, setSelectedFileError] = useState<string>("");
  const [uploadResult, setUploadResult] = useState<any>();
  const [uploadHistory, setUploadHistory] = useState<any>([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [csvArray, setCsvArray] = useState([]);
  const [tempHeaders, setTempHeaders] = useState<any>(null);
  const [isCsvUploading, setIsCsvUploading] = useState<any>(false);
  const dispatch = useAppDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getFlowMappingHistory()).then((res: any) => {
      if (res?.payload?.status === 200) {
        setUploadHistory(res?.payload?.data);
      }
    });
  }, []);
  const handleCsv = ({ target: { value } }: any) => {
    setSelectedCsv(value);

    dispatch(value === "CSV" ? getFlowMappingHistory() : getVcrHistory()).then(
      (res: any) => {
        if (res?.payload?.status === 200) {
          setUploadHistory(res?.payload?.data);
        }
      }
    );
  };
  const handleDownload = () => {
    if (selectedCsv === "CSV") {
      dispatch(downloadCSV());
    } else {
      dispatch(downloadVisibilityConditionRef());
    }
  };

  const handleSubmit = (e?: any) => {
    e.preventDefault();

    const allowedFileTypes = [".csv", ".tsv", ".txt"];
    const fileName = selectedFile?.name || "";
    const fileExtension = fileName.substring(fileName.lastIndexOf("."));

    if (selectedFile === null || !allowedFileTypes.includes(fileExtension)) {
      setSelectedFileError(
        "Please select any .csv, .tsv or .txt file to upload."
      );
      return;
    } else {
      setSelectedFileError("");
    }

    if (selectedCsv === "CSV") {
      dispatch(uploadCSV(selectedFile)).then((res: any) => {
        if (res?.payload?.status === 200) {
          setUploadResult(res?.payload?.data);
          setSelectedFile(null);
          setSelectedFileError("");
          handleClose();
        }
      });
    } else {
      dispatch(uploadVisibilityConditionRef(selectedFile)).then((res: any) => {
        if (res?.payload?.status === 200) {
          setSelectedFile(null);
          setSelectedFileError("");
          handleClose();
        }
      });
    }
  };

  const handleClose = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  const processCSV = (str: any, delim = "\t") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row: any) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj: any, header: any, i: number) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });

    setTempHeaders(headers);
    setCsvArray(newArray);
  };

  const handlePreview = () => {
    if (selectedFile) {
      setIsPreviewOpen(true);

      const file = selectedFile;
      const reader = new FileReader();

      reader.onload = function (e: any) {
        const text = e.target.result;
        processCSV(text);
      };

      reader.readAsText(file);
    } else {
      setSelectedFileError(
        "Please select any .csv, .tsv or .txt file to upload."
      );
    }
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="tabs"
        className="header-tabbar"
      >
        <Tab label="Upload/Download" value={"1"} />
        <Tab label="History" value={"2"} />
      </Tabs>
      {value === "1" && (
        <Box sx={{ p: "20px" }}>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel
              sx={{ pr: 1, background: "white" }}
              id="demo-simple-select-label"
            >
              CSV
            </InputLabel>
            <Select
              value={selectedCsv}
              name="CaseType"
              onChange={(e: any) => handleCsv(e)}
            >
              <MenuItem value={"CSV"}>Flow Mapping</MenuItem>
              <MenuItem value={"VisibilityConditionRef"}>
                Visibility Condition Ref
              </MenuItem>
            </Select>
          </FormControl>
          <form onSubmit={handleSubmit}>
            <div className="flex my-5 items-center">
              <strong className="me-2">Choose a file:</strong>
              <Button variant="contained" component="label">
                Upload File
                <input
                  type="file"
                  accept={".csv, .tsv, .txt"}
                  className="hidden"
                  onChange={(e: any) => setSelectedFile(e.target.files[0])}
                />
              </Button>
              <Typography sx={{ ml: 2 }}>{selectedFile?.name}</Typography>
              {selectedFileError && !selectedFile?.name && (
                <Typography sx={{ ml: 2, color: "red" }}>
                  {selectedFileError}
                </Typography>
              )}
            </div>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                type="button"
                sx={{
                  px: "10px",
                  py: "5px",
                  background: "#2557b3",
                  color: "#fff",
                  fontSize: "14px",
                  ":hover": {
                    backgroundColor: "rgba(37, 87, 179, 0.9)",
                  },
                }}
                onClick={() => handlePreview()}
              >
                Upload
              </Button>
              <Button
                type="button"
                onClick={handleDownload}
                sx={{
                  px: "10px",
                  py: "5px",
                  background: "#2557b3",
                  color: "#fff",
                  fontSize: "14px",
                  ":hover": {
                    backgroundColor: "rgba(37, 87, 179, 0.9)",
                  },
                }}
              >
                Download
              </Button>
            </Box>
            <Box>
              <div
                className="jsonViewer"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                <p>
                  <strong className="me-2">Result:</strong>
                </p>
                <ReactJson
                  src={uploadResult || {}}
                  enableClipboard={false}
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              </div>
            </Box>
          </form>

          <CSVPreview />
        </Box>
      )}

      {value === "2" && <History {...{ uploadHistory }} />}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isPreviewOpen}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: "14px" } }}
      >
        {loading && <BackDropLoader text="Uploading..." />}
        <DialogTitle>
          <span className="text-lg text-gray-900">
            Preview: {selectedFile?.name}
          </span>
        </DialogTitle>
        <DialogContent>
          <CsvReader headers={tempHeaders} csvArray={csvArray} />
          <h3 className="mt-3" style={{ fontSize: 18 }}>
            This will replace the existing rows of {selectedFile?.name}. Are you
            sure you would like to proceed?
          </h3>
        </DialogContent>
        <DialogActions>
          <Button
            color="error"
            variant="contained"
            onClick={handleSubmit}
            // disabled={!name || !type}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClose()}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CsvManagement;
