import React from "react";

export default function PostContents(props: any) {
  const { contentData } = props;

  const matches = contentData?.match(
    /\[caption id="(.*?)" align="(.*?)" width="(\d+)"\]<img class="(.*?)" src="(.*?)" alt="(.*?)" width="(\d+)" height="(\d+)" \/>(.*?)\[\/caption\]/
  );

  if (!matches) {
    const contentWithStyles = {
      __html: contentData
        // .replace(
        //   /<a/g,
        //   '<a style="text-decoration: underline; color: #007bff;"'
        // )
        // .replace(
        //   /<a class="intercom-h2b-button"/g,
        //   '<a class="intercom-h2b-button button-style"'
        // )
        .replace(
          /<p/g,
          '<p style="margin-bottom: 10px; white-space: pre-line; font-size: 12px; line-height: 1.5; color: #000;'
        )
        .replace(/<a/, '<a style="text-decoration: underline; color: #007bff;"')
        .replace(
          /<table/g,
          '<table style="width: 100%; border-collapse: collapse; margin-bottom: 10px;"'
        )
        .replace(
          /<th/g,
          '<th style="border: 1px solid #ddd; padding: 8px; text-align: left; background-color: #f2f2f2;"'
        )
        .replace(
          /<td/g,
          '<td style="border: 1px solid #ddd; padding: 8px; text-align: left;"'
        )
        .replace(
          /<div class="intercom-interblocks-callout"/g,
          '<div class="intercom-interblocks-callout callout-container-style"'
        )
        .replace(/<h1/g, '<h1 class="custom-h1-header-style"')
        .replace(/<h2/g, '<h2 class="custom-h2-header-style"'),
    };

    return (
      <div
        className="faq_container"
        dangerouslySetInnerHTML={contentWithStyles}
      />
    );
  }

  const [
    ,
    id,
    align,
    width,
    className,
    src,
    alt,
    imgWidth,
    imgHeight,
    captionText,
  ] = matches;

  return (
    <div>
      <h2>{captionText}</h2>
      <figure id={id} className={align} style={{ width: `${width}px` }}>
        <img
          className={className}
          src={`https://usapath.azurewebsites.net${src}`}
          alt={alt}
          width={imgWidth}
          height={imgHeight}
        />
        <figcaption>{captionText}</figcaption>
      </figure>
    </div>
  );
}
